import * as actionTypes from 'src/actions';

const initialState = {
	loggedIn: false,
	user: {
		role: 'GUEST' // ['GUEST', 'USER', 'ADMIN']
	},
};

const sessionReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SESSION_LOGIN: {
			return {
				...state,
				loggedIn: true,
				user: action.payload.user,
				sponsor: action.payload.sponsor
			};
		}

		case actionTypes.SESSION_LOGOUT: {
			return {
				...initialState,
				website: state.website,
				project: state.project
			};
		}

		case actionTypes.REFRESH_TOKEN: {
			const newState = { ...state };
			newState.user.access_token = action.payload.access_token;
			return {
				...newState,
			};
		}

		default: {
			return state;
		}
	}
};

export default sessionReducer;
