export default {
	app_name: 'eKYC',
	btn: {
		next: 'ถัดไป',
	},
	overview: {
		title: 'eKYC - Step 2',
		caption: 'ขั้นตอนที่ 2',
		header: 'สแกนใบหน้า',
		subheader: 'สแกนใบหน้าเพื่อพิสูจน์ความเป็นบุคคล',
		suggestion: {
			header: 'คำแนะนำ',
			item: 'อยู่ในบริเวณที่มีแสงสว่างเพียงพอ และ ไม่ใส่หมวก แว่นตา  ไม่มีอะไรมาปิดบังใบหน้า',
			item_2: 'หน้าตรง และวางใบหน้าให้อยู่ในกรอบที่กำหนด',
			item_3: 'กระพริบตา  1 ครั้งเพื่อถ่ายภาพ',
		},
		remark: 'ข้อมูลของคุณจะถูกจัดเก็บอย่างปลอดภัย'
	},
};
