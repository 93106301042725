export default {
	app_name: 'eKYC',
	btn: {
		next: 'next',
	},
	overview: {
		title: 'eKYC - Step 2',
		caption: 'STEP 2',
		header: 'Scan your face',
		subheader: 'Scan you face to identify your ID Card',
		suggestion: {
			header: 'Suggestion',
			item: 'Make sure your face is on even lighting and not covered',
			item_2: 'Center your face and stay still',
			item_3: 'Blink your eye 1 time to take a picture',
		},
		remark: 'Your information will be kept securely'
	},
};
