export const SESSION_LOGIN = 'SESSION_LOGIN';
export const SESSION_LOGOUT = 'SESSION_LOGOUT';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';

export const login = (payload) => (dispatch) => dispatch({
	type: SESSION_LOGIN,
	payload
});

export const logout = () => (dispatch) => dispatch({
	type: SESSION_LOGOUT
});

export const refreshToken = (payload) => (dispatch) => dispatch({
	type: REFRESH_TOKEN,
	payload
});
